<template>
  <div class="indexes container">
    <div class="brcrumb" v-if="token">
      <router-link :to="{ name: 'games' }">Games</router-link> <span>--></span>
      <h5>game {{ this.$route.params.id }}</h5>
    </div>
    <div class="index-table">
      <div class="index-head">
        <h2 class="index-text">{{ game.name }}</h2>
        <PanelBaseButton type="submit" style="width: fit-content" @click="goToPurchases">
          In-App Purchase Items
        </PanelBaseButton>
      </div>
      <BaseLoading class="fit-height" v-if="loading">Loading...</BaseLoading>
      <div v-else>
        <GameGallery :game="game"></GameGallery>
        <GameMode :game="game"></GameMode>
        <GameTournaments :game="game"></GameTournaments>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GameMode from "@/components/panelSections/games/game/GameMode.vue";
import GameGallery from "@/components/panelSections/games/game/GameGallery.vue";
import GameTournaments from "@/components/panelSections/games/game/GameTournaments.vue";
export default {
  components: {
    GameMode,
    GameGallery,
    GameTournaments,
  },
  data() {
    return {
      loading: false,
      game: {},
    };
  },
  computed: { ...mapGetters(["token"]) },
  created() {
    this.fetchGame();
  },
  methods: {
    ...mapActions(["getSingleGame"]),
    fetchGame() {
      this.loading = true;
      this.getSingleGame(this.$route.params.id)
        .then((result) => {
          this.game = result.data.result;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    goToPurchases() {
      // this.$router.push({ name: "purchases", params: { id: this.game.id } });
      this.$router.push({
        name: `${this.token ? "purchases" : "unauth-user-purchases"}`,
        params: { id: this.game.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_indexes.scss";
.brcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  a {
    font-weight: 800;
    color: var(--color7);
  }
  span {
    margin: 0 10px;
    color: white;
  }
  h5 {
    font-weight: 100;
    color: var(--color8);
  }
}
.index-table {
  min-height: 90vh;
  .index-head {
    margin-bottom: 20px;
  }
}
.fit-height {
  height: 100vh;
}
</style>
