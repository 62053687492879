<template>
  <div class="table">
    <h3 style="color: white">Tournaments</h3>
    <NoResult
      v-if="!tournaments || (!tournaments.length && !loading)"
      class="fit-height"
    ></NoResult>
    <div v-else class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <li class="headline">
            <div class="name">Tournament</div>
            <div class="date center">Date</div>
            <div class="price center">info</div>
            <div class="rewards center">Rewards</div>
            <div class="actions center">Actions</div>
          </li>
          <TournamentItem
            v-for="(tournament, i) in tournaments"
            :key="i"
            :tournament="tournament"
            @showDetails="showDetails"
            @showLeaderboard="showLeaderboard"
            @joinTournament="joinTournament"
          ></TournamentItem>
        </ul>
        <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination>
      </div>
    </div>

    <!-- tournament details modal -->
    <BaseModal
      :dialog="tournamentDetailsDialog"
      @close="tournamentDetailsDialog = false"
      title="Tournament Details"
    >
      <TournamentDetails
        :item="itemSelected"
        @DetailJoinedTrSuccessfully="DetailJoinedTrSuccessfully"
      ></TournamentDetails>
    </BaseModal>
    <!-- tournament details modal -->

    <!-- tournament leaderboard modal -->
    <BaseModal
      :dialog="leaderboardDialog"
      @close="leaderboardDialog = false"
      title="Tournament leaderboard"
    >
      <TournamentLeaderboard :item="itemSelected"></TournamentLeaderboard>
    </BaseModal>
    <!-- tournament leaderboard modal -->

    <!-- join confirmation modal -->
    <BaseModal
      :dialog="joinConfirmationDialog"
      @close="joinConfirmationDialog = false"
      title="join Tournament confirm"
    >
      <JoinConfirmation
        :item="itemSelected"
        @cancleJoin="joinConfirmationDialog = false"
        @joinedTrSuccessfully="joinedTrSuccessfully"
      ></JoinConfirmation>
    </BaseModal>
    <!-- join confirmation modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["game"],
  data() {
    return {
      tournaments: [],
      itemSelected: null,
      tournamentDetailsDialog: false,
      leaderboardDialog: false,
      joinConfirmationDialog: false,
      page: 1,
      total: 0,
      lastPage: 1,
      loading: false,
    };
  },
  computed: { ...mapGetters(["token"]) },
  created() {
    this.fetchAllTournamets();
  },
  methods: {
    ...mapActions(["getGameTournaments"]),
    fetchAllTournamets() {
      let params = new URLSearchParams({ page: this.page, per_page: 5 });
      const id = this.game.id;
      this.loading = true;
      this.getGameTournaments({ id, params }).then((res) => {
        this.tournaments = res.data.result.data;
        this.total = res.data.result.total;
        this.lastPage = res.data.result.last_page;
        this.loading = false;
      });
    },
    showDetails(touranment) {
      this.itemSelected = touranment;
      this.tournamentDetailsDialog = true;
    },
    showLeaderboard(touranment) {
      this.itemSelected = touranment;
      this.leaderboardDialog = true;
    },
    joinTournament(tournament) {
      if (!tournament.is_joined) {
        this.itemSelected = tournament;
        this.joinConfirmationDialog = true;
      } else {
        if (window.innerWidth < 540) {
          window.open(
            `${process.env.VUE_APP_GOTO_TOURNAMENT}?gameId=${tournament.game_id}&tournamentId=${tournament.id}&token=${this.token}`
          );
        } else {
          window.open(
            `https://minigames.ecogamecenter.net/games/${this.game.id}/?orientation=${this.game.conf.webgl.orientation}&&token=${this.token}`,
            "_blank"
          );
          // this.$router.push({
          //   name: "play-game",
          //   params: {
          //     gameId: this.game.id,
          //     orientation: this.game.conf.webgl.orientation,
          //   },
          // });
        }
      }
    },
    joinedTrSuccessfully() {
      this.joinConfirmationDialog = false;
      this.fetchAllTournamets();
    },
    DetailJoinedTrSuccessfully() {
      this.tournamentDetailsDialog = false;
      this.fetchAllTournamets();
    },
    changePage(val) {
      this.page = val;
      this.fetchAllTournamets();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
.table {
  // border: 2px solid white;
  border-radius: 5px;
  padding: 15px;
  padding-bottom: 30px;
  background: var(--home-bg);
  margin-top: 30px;
}
ul {
  @include md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .headline {
    @include md {
      display: none !important;
    }
  }
}
.fit-height {
  height: 50vh;
}
</style>
