<template>
  <div class="game-mode">
    <div
      class="game-container"
      :style="`width:${game.conf.webgl.width}px;height:${game.conf.webgl.height}px`"
    >
      <div class="banner" v-if="!isStarted">
        <img class="img" :src="imageLink(game.image)" alt="" />
        <button class="start-btn">
          <a
            :href="`https://minigames.ecogamecenter.net/games/${game.id}/?orientation=${game.conf.webgl.orientation}&&token=${token}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="bx bx-play-circle bx-burst"></i>
          </a>
        </button>
      </div>
      <div class="actions">
        <button class="actions-btn download" @click="downloadApk">
          Download APK <i class="bx bxs-download"></i>
        </button>
        <button class="actions-btn play" @click="playOnDevice">
          Play Now <i class="bx bx-play"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["game"],
  data() {
    return {
      isStarted: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
    // gameLink() {
    //   return `${process.env.VUE_APP_GAME_URL}/${this.$route.params.id}?token=${this.token}`;
    // },
  },
  created() {
    console.log(document.referrer);
  },
  methods: {
    imageLink(image) {
      return `${process.env.VUE_APP_ROOT_STORAGE}/${image}`;
    },
    startGame() {
      // window.innerWidth < 1030
      //   ? window.open(
      //       `https://minigames.ecogamecenter.net/games/${this.$route.params.id}/?token=${this.token}`
      //     )
      //   : (this.isStarted = true);
      this.$router.push({
        name: "play-game",
        params: {
          gameId: this.game.id,
          orientation: this.game.conf.webgl.orientation,
        },
      });
    },
    downloadApk() {
      console.log(this.game.conf);
      window.open(this.game.conf.donwload);
    },
    playOnDevice() {
      window.open(
        `app://minigames.ecogamecenter.net/games/${this.$route.params.id}/?token=${this.token}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.game-mode {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  .game-container {
    width: 310px;
    height: 590px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color7);
    box-shadow: 0 0 20px 10px black;
    border-radius: 5px;
    .banner {
      &::before {
        content: "";
        background: linear-gradient(
          220deg,
          rgba(0, 0, 0, 0.753),
          rgba(4, 0, 255, 0.226)
        );
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .start-btn {
        background: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        i {
          font-size: 60px;
          color: var(--color7);
          transition: font-size 0.5s ease;
          text-shadow: 0 0 5px rgb(0, 0, 0);

          &:hover {
            font-size: 60px;
            color: white;
          }
        }
      }
    }
    .iframe {
      // border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      @media only screen and (max-width: 1030px) {
        display: none;
      }
    }
    .actions {
      position: absolute;
      left: 0;
      top: 70%;
      width: 100%;
      display: none;
      align-items: center;
      z-index: 2;
      @include md {
        display: flex;
        flex-direction: column;
      }
      .actions-btn {
        width: 70%;

        padding: 8px 0;
        border-radius: 3px;
        box-shadow: 0 0 10px 5px black;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0;
        i {
          margin-left: 15px;
        }
        &.download {
          background: green;
        }
        &.play {
          background: rgb(247, 247, 247);
        }
      }
    }
  }
}
</style>
