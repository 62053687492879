<template>
  <div>
    <transition-group name="list" tag="ul" class="slides">
      <li
        v-for="(item, i) in visibleMedia"
        :key="i"
        class="slide"
        :style="`width:${100 / itemsWantToShow}%`"
      >
        <img class="game-img" :src="mediaLink(item.file)" />
      </li>
    </transition-group>
    <ul class="points">
      <li
        class="point"
        style="color: white"
        v-for="(i, index) in points"
        :key="index"
        @click="changePoint(index)"
        :class="{ active: activePoint === index }"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["game"],
  data() {
    return {
      loading: false,
      points: 0,
      activePoint: null,
      renderPicturesLoading: false,
      media: [],
      visibleMedia: [],
      itemsWantToShow: null,
    };
  },
  computed: {},
  created() {
    this.media = this.game.medias;
    this.handleItemsWantToShow();
    // this.changePoint(0);
    // this.handlePoints();
    window.addEventListener("resize", () => {
      this.handleItemsWantToShow();
      this.activePoint = null;
      this.handlePoints();
      this.changePoint(0);
    });
  },
  methods: {
    handleItemsWantToShow() {
      if (window.innerWidth < 450) this.itemsWantToShow = 1;
      else if (window.innerWidth > 450 && window.innerWidth < 950)
        this.itemsWantToShow = 3;
      else this.itemsWantToShow = 5;
    },
    handlePoints() {
      this.breakpointWatched = false;
      this.points = Math.ceil(this.media.length / this.itemsWantToShow);
    },
    changePoint(index) {
      if (index !== this.activePoint && !this.renderPicturesLoading) {
        this.renderPicturesLoading = true;
        this.activePoint = index;
        let copyOfMedia = [...this.media];
        let firstIndex = index * this.itemsWantToShow;
        let lastIndex = firstIndex + this.itemsWantToShow;
        let visMedia = copyOfMedia.slice(firstIndex, lastIndex);
        this.visibleMedia = [];
        visMedia.forEach((element) => {
          //this function needed for transition
          setTimeout(() => {
            this.visibleMedia.push(element);
            this.renderPicturesLoading = false;
          }, 501);
        });
      }
    },
    mediaLink(file) {
      return `${process.env.VUE_APP_ROOT_STORAGE}/${file}`;
    },
  },
  watch: {
    itemsWantToShow() {
      this.handlePoints();
      this.changePoint(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.slides {
  display: flex;
  justify-content: center;
  min-height: 35vh;
  .slide {
    width: 20%;
    margin: 6px;
    display: inline-block;
    border: 2px solid var(--color7);
    border-radius: 5px;
    overflow: hidden;
    .game-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // max-height: 180px;
    }
  }
}
.points {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .point {
    width: 15px;
    height: 15px;
    border: 2px solid white;
    margin: 0 10px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.5s ease;
    &.active {
      background: white;
      transform: scale(1.3);
    }
  }
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.list-enter-to,
.list-leave {
  opacity: 1;
  transform: scale(1);
}
</style>
